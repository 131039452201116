<template>
  <div>
    <iframe
      src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Asia%2FManila&hl=en&bgcolor=%23ffffff&src=MzYxZmE1MWQyNDczYTY5Yjg0ZWU5NjY5M2Q0YWQ3ZjZlZWUyYjcwNGYwNDUwNTRlNGIzZTAzNTgwODBiZmJiNUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=YjdjZDYyYTlhZGQ0ZTU5Njc0YTQ1NzRjNjIxM2FiMjFhZTdjODk2NjczMjg0NDFkZDcyZWZmZDM5MjA0ZmUxY0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=NjVjM2RmMTFiYzNlZGZhMTgwNmY1YWU5MTIzZTMxNjJlYTFlNWE1MjI2NGM4YWVmZTk2NTg1YjY2ZTE4MzM4YUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=NDRkNDRkYTkzNjgzMTQwYWRiMTk1NzRhZWU2NGY5NmIwNTA1ZjFlOWJhYzY4ZDUwNzk3Mzk0NWNjZmY4MjdmYUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=ZW4ucGhpbGlwcGluZXMjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%234285F4&color=%230B8043&color=%23F4511E&color=%23EF6C00&color=%23D50000"
      style="border:solid 1px #777"
      width="100%"
      height="800"
      frameborder="0"
      scrolling="no"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'Calendar',
};
</script>
